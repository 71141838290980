<template>
  <div>
    <div class="mx-2">
      <!-- <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb> -->

      <h3 class="mt-5">
        {{
          $getVisibleNames("mesh.competencetype", true, "Tipos de Competencias")
        }}
        <AlternativeNameButton
          :table_name="'mesh.competencetype'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
        <!-- Tipos de Competencias -->
      </h3>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <ButtonMedium
            v-can="'mesh.add_competencetype'"
            v-if="allows_crud"
            :tooltip_text="`Agregar ${$getVisibleNames(
              'mesh.competencetype',
              false,
              'Tipo De Competencia'
            )}`"
            :text_button="`Agregar`"
            :click_button="() => $bvModal.show(`new-competence-type-modal`)"
            :icon="'plus'"
            :variant="'primary'"
          >
          </ButtonMedium>
          <!-- <button
            v-can="'mesh.add_competencetype'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-competence-type-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.competencetype",
                false,
                "Tipo De Competencia"
              )
            }}
          </button> -->
          <!-- Tipo de Competencia -->
        </div>
      </div>
      <GenericBTable
        v-if="searchCompetenceType.length > 0"
        class="competence-type-table"
        :items="competence_types"
        :pagination="competence_types.length"
        :fields="CompetenceTypesFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-if="allows_crud" #cell(actions)="row">
          <ButtonSmall
            :tooltip_text="`Editar ${$getVisibleNames(
              'mesh.competencetype',
              false,
              'Tipo De Competencia'
            )}`"
            :click_button="
              () => $bvModal.show(`edit-competence-type-modal-${row.item.id}`)
            "
            :class="`mx-1`"
            :icon="'square'"
          ></ButtonSmall>
          <!-- <button-edit
            @click="$bvModal.show(`edit-competence-type-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.competencetype',
                false,
                'Tipo De Competencia'
              )}`
            "
          ></button-edit> -->
          <ButtonSmall
            :click_button="() => askForDeleteCompetenceType(row.item.id)"
            :tooltip_text="`Eliminar ${$getVisibleNames(
              'mesh.competencetype',
              false,
              'Tipo De Competencia'
            )}`"
            :icon="'trash'"
          ></ButtonSmall>
          <!-- <button-delete
            @click="askForDeleteCompetenceType(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.competencetype',
                false,
                'Tipo De Competencia'
              )}`
            "
          ></button-delete> -->
          <b-modal
            :id="`edit-competence-type-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.competencetype',
              false,
              'Tipo De Competencia'
            )}`"
            size="md"
            hide-footer
          >
            <CompetenceTypeForm
              :CompetenceType="row.item"
              @updated="slotUpdatedCompetenceType"
              :show_title="false"
            ></CompetenceTypeForm>
          </b-modal>
        </template>
      </GenericBTable>
      <div v-else>
        <br />
        <!-- tipos de competencia -->
        <strong
          >No se han encontrado
          {{
            $getVisibleNames(
              "mesh.competencetype",
              true,
              "Tipos De Competencias"
            )
          }}.</strong
        >
      </div>
    </div>
    <b-modal
      :id="`new-competence-type-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.competencetype',
        false,
        'Tipo De Competencia'
      )}`"
      size="md"
    >
      <CompetenceTypeForm
        @created="slotCreatedCompetenceType"
        :show_title="false"
      ></CompetenceTypeForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "CompetenceTypeView",
  components: {
    CompetenceTypeForm: () =>
      import(
        "@/components/mesh/CurricularResources/CompetenceType/CompetenceTypeForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
    display_id: {},
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      competence_types: names.COMPETENCE_TYPES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_competencetype");
      if (has_permission) return has_permission;
      else return false;
    },
    searchCompetenceType: function () {
      return this.competence_types
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(this.input_search.toLowerCase())
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    CompetenceTypesFields() {
      if (!this.allows_crud)
        return this.competence_types_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.competence_types_fields;
    },
    // breadcrumb_items() {
    //   return [
    //     { text: "Definiciones Curriculares", active: true },
    //     {
    //       text: String(
    //         this.$getVisibleNames(
    //           "mesh.competencetype",
    //           true,
    //           "Tipos De Competencias"
    //         )
    //       ),
    //       active: true,
    //     },
    //   ];
    // },
    competence_types_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.competencetype",
              true,
              "Tipos De Competencias"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "order",
          label: "Orden",
          thStyle: { background: "var(--primary-color) !important" },
          sortable: true,
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    askForDeleteCompetenceType(competence_type_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.competencetype",
          false,
          "Tipo De Competencia"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_COMPETENCE_TYPE, competence_type_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.competencetype",
                    false,
                    "Tipo De Competencia"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedCompetenceType() {
      this.$bvModal.hide("new-competence-type-modal");
    },
    slotUpdatedCompetenceType(competence_type) {
      this.$bvModal.hide(`edit-competence-type-modal-${competence_type.id}`);
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
  },
};
</script>
<style scoped>
.td-actions {
  max-width: 100px !important;
}
.title-header {
  padding: 0 1rem;
  margin-top: auto;
}
.search-competence-type {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.75em;
}
</style>